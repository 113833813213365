import { Routes, Route } from 'react-router-dom'
import Footer from './Component/Footer'
import Header from './Component/Header'
import Hero from './Component/Hero'
import Menu from './Component/Pages/Menu'
import About from './Component/Pages/About'
import Gallery from './Component/Pages/Gallery'
import Rooms from './Component/Pages/Rooms'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Lenis from '@studio-freight/lenis'
import SplitType from 'split-type'



function App() {
  const lenis = new Lenis()

  function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }
  requestAnimationFrame(raf)

  gsap.registerPlugin(ScrollTrigger)

  const animText = new SplitType('.anim', { type: 'words,chars' });

  gsap.set(animText.chars, {
    scale: 0.5, opacity: 0.2
  })

  gsap.to(animText.chars, {
    scale: 1,
    opacity: 1,
    stagger: .05,
    scrollTrigger: {
      trigger: '.anim',
      start: 'top 50%',
      end: 'bottom 100%',
      scrub: true
    }
  })

  window.addEventListener('resize', () => {
    ScrollTrigger.refresh();
});

window.addEventListener('orientationchange', () => {
    ScrollTrigger.refresh();
});

  return (
    <>
      <Header className='' />
      <Routes>
        <Route path='/' element={<Hero />} />
        <Route path='menu' element={<Menu />} />
        <Route path='about' element={<About />} />
        <Route path='gallery' element={<Gallery />} />
        <Route path='rooms' element={<Rooms />} />
      </Routes>


      <Footer />
    </>
  )
}

export default App
