import React, { useEffect } from 'react'
import rooms from '../../assets/images/rooms.png'
import downarrow from '../../assets/images/down-arrow.svg'
import circleText from '../../assets/images/circle-text.png'
import roomsmain from '../../assets/images/rooms-main.png'
import roomsOne from '../../assets/images/rooms-1.png'
import roomsTwo from '../../assets/images/rooms-2.png'
import roomsThree from '../../assets/images/rooms-3.png'
import amenIconOne from '../../assets/images/amen-icon-1.svg'
import amenIconTwo from '../../assets/images/amen-icon-2.svg'
import amenIconThree from '../../assets/images/amen-icon-3.svg'
import amenIconFour from '../../assets/images/amen-icon-4.svg'
import amenIconFive from '../../assets/images/amen-icon-5.svg'
import amenIconSix from '../../assets/images/amen-icon-6.svg'
import amenIconSeven from '../../assets/images/amen-icon-7.svg'
import amenIconEigth from '../../assets/images/amen-icon-8.svg'
import rightarrow from '../../assets/images/right-arrow.svg'
import roomsdesk from '../../assets/images/rooms-desk.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Rooms = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>


            <div className="slider relative h-screen w-full bg-gradient-to-tl from-gray-900 to-gray-400 -z-10">
                <div className="h-screen  bg-center bg-cover bg-no-repeat object-contain w-full mix-blend-overlay absolute" style={{ backgroundImage: `url(${rooms})` }}>
                </div>

            </div>

            <div className="h-screen absolute top-1/2 left-1/2 -z-10  transform -translate-x-1/2 -translate-y-1/2">

                <div className="h-screen items-center justify-center flex flex-col text-center text-white space-y-10">
                    <h1 className='font-anti text-5xl md:text-[80px] font-w-[400px]'>Luxury Room</h1>
                    <p className='font-curban text-2xl'>
                        Explore the Gallery and get <br />
                        unforgettable experience
                    </p>
                </div>



            </div>

            {/* scroll donw */}

            <div className="absolute bottom-14 right-20 hidden md:block">
                <div className="  w-[100px] h-[100px] rounded-full bg-center bg-cover bg-no-repeat " style={{ backgroundImage: `url(${circleText})` }}>
                    <img src={downarrow} alt="" className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[20px] animate-ping aimate-bounse' />
                </div>

            </div>


            <div className=" ">
                <h2 className='text-4xl md:text-[128px] text-right p-[10px] md:p-[100px] text-[#5C6C68] font-anti opacity-50' data-aos="fade-up">Rooms</h2>
            </div>

            {/* rooms section */}

            <div className="grid grid-cols-1 md:grid-cols-2 p-[10px] md:px-[100px]" >
                <div className="" data-aos='fade-up'>
                    <div className="" >
                        <img src={roomsmain} alt="" className='object-contain' data-aos='zoom-in' data-aos-delay="500" data-aos-easing="ease-in" />
                    </div>
                    <div className="grid grid-cols-3 my-2 gap-2">
                        <img src={roomsOne} alt="" className='object-contain' />
                        <img src={roomsTwo} alt="" className='object-contain' />
                        <img src={roomsThree} alt="" className='object-contain' />
                    </div>
                </div>

                <div className="px-10 text-[#5C6C68] space-y-7" data-aos='fade-down'>
                    <h2 className='text-4xl md:text-[65px]'>Vintage Villas</h2>
                    <p className='text-xl'>The people, food and the prime locations make Rodich the perfect place good friends & family to come together and have great time.</p>

                    <h5 className='text-xl md:text-3xl md:py-11'>Our Amenties</h5>

                    <div className="amenties space-y-10">
                        <div className="grid grid-cols-2 gap-2">
                            <div className="flex gap-4 items-center text-xl">
                                <p><img src={amenIconOne} alt="" /></p>
                                <p>Swimming Pool</p>
                            </div>
                            <div className="flex gap-4 text-xl items-center">
                                <p><img src={amenIconTwo} alt="" /></p>
                                <p>2 Beds </p>
                            </div>

                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="flex gap-4 items-center text-xl">
                                <p><img src={amenIconThree} alt="" /></p>
                                <p>Traditional Spa</p>
                            </div>
                            <div className="flex gap-4 text-xl items-center">
                                <p><img src={amenIconFour} alt="" /></p>
                                <p>2 Baths </p>
                            </div>

                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="flex gap-4 items-center text-xl">
                                <p><img src={amenIconFive} alt="" /></p>
                                <p>Fitness Centre</p>
                            </div>
                            <div className="flex gap-4 text-xl items-center">
                                <p><img src={amenIconSix} alt="" /></p>
                                <p>Free Wifi </p>
                            </div>

                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="flex gap-4 items-center text-xl">
                                <p><img src={amenIconSeven} alt="" /></p>
                                <p>Parking Facilities</p>
                            </div>
                            <div className="flex gap-4 text-xl items-center">
                                <p><img src={amenIconEigth} alt="" /></p>
                                <p>Restaurant</p>
                            </div>

                        </div>

                        <button className='py-2 px-4  border border-gray-500'><span><img src={rightarrow} alt="" className='inline pr-2' /></span><span className='inline'>Book Now</span></button>
                    </div>
                </div>
            </div>


            {/* rooms sub title */}

            <h2 className='px-[10px] md:px-[100px] text-2xl my-7 text-[#5C6C68] md:text-4xl'>Check out <br />
                Our Authentic Villas
            </h2>

            {/* image full cover rooms section */}

            <div className="grid grid-cols-3">
                <div className="relative">
                    <img src={roomsdesk} alt="" className='object-contain' />
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white text-4xl font-urban space-y-6">
                        <h2 className='md:text-[64px] font-light'>Blossom</h2>
                        <h3 className='md:text-[64px] font-normal'>world</h3>
                    </div>
                </div>
                <div className="relative">
                    <img src={roomsdesk} alt="" className='object-contain' />
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white text-4xl font-urban space-y-6">
                        <h2 className='md:text-[64px] font-light'>Bliss</h2>
                        <h3 className='md:text-[64px] font-normal'>world</h3>
                    </div>
                </div>
                <div className="relative">
                    <img src={roomsdesk} alt="" className='object-contain' />
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-white text-4xl font-urban space-y-6">
                        <h2 className='md:text-[64px] font-light'>Sauna</h2>
                        <h3 className='md:text-[64px] font-normal'>world</h3>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Rooms