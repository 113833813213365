import React, { useState, useEffect } from 'react'
import './Animation.css'
import downarrow from '../assets/images/down-arrow.svg'
import circleText from '../assets/images/circle-text.png'
import suites from '../assets/images/suites.png'
import Slider from './Slider'
// import ExpolareSlider from './ExpolareSlider'
import story from '../assets/images/story.png'
import rightarrow from '../assets/images/right-arrow.svg'
import videothumb from '../assets/images/video-thumb.png'
import gallery1 from '../assets/images/g-1.png'
import gallery2 from '../assets/images/g-2.png'
import gallery3 from '../assets/images/g-3.png'
import gallery4 from '../assets/images/g-4.png'
import gallery5 from '../assets/images/g-5.png'
import imgOne from '../assets/images/images-1.svg'
import imgTwo from '../assets/images/images-2.svg'
import imgThree from '../assets/images/images-3.svg'
import hero1 from '../assets/images/hero-1.png';
import hero2 from '../assets/images/hero-2.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import TestiData from './TestiData'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ExpolareSlider from './ExpolareSlider'


gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
    // const zoomInRef = useRef(null);
    // const imageRefs = useRef(null);
    // const { innerHeight } = window;

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(".your-image-class", {
            scale: 1,
            ease: "none",
            scrollTrigger: {
                trigger: ".your-image-class",
                start: "top bottom", // when the top of the trigger hits the bottom of the viewport
                end: "bottom top", // when the bottom of the trigger hits the top of the viewport
                scrub: true
            }
        });
    }, []);


    // useEffect(() => {


    //     gsap.to(imageRefs.current, {
    //       opacity: 0,
    //       scale: 0.1, // Shrink to 10% of the original size
    //       scrollTrigger: {
    //         trigger: imageRefs.current,
    //         stagger: 0.25,
    //         duration: 10,
    //         start: 'top 20%',
    //         end: `+=${innerHeight * 1}`,
    //         scrub: true,

    //       },
    //     });
    //   }, [innerHeight]);

    useEffect(() => {
        AOS.init();
    }, []);

    const images = [
        hero1,
        hero2,
        hero1,
        // Add more image paths as needed
    ];

    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000); // Change the interval as needed (e.g., 5000 milliseconds = 5 seconds)

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <>

            <div className="slider relative h-screen w-full bg-gradient-to-tl from-gray-700 to-gray-700 -z-10" >
                <div className="h-screen  bg-center bg-cover bg-no-repeat object-contain w-full absolute mix-blend-overlay" style={{ backgroundImage: `url(${images[currentImage]})` }}>
                </div>

            </div>

            <div className="h-screen absolute top-1/2 left-1/2 -z-10  transform -translate-x-1/2 -translate-y-1/2">

                <div className="h-screen items-center justify-center flex flex-col text-center text-white space-y-10">
                    <h1 className='font-anti text-5xl md:text-[80px] font-w-[400px]'>Courtyard Castle</h1>
                    <p className='font-curban text-2xl'>
                        Culinary art is an important part of the <br />
                        unforgettable experience
                    </p>
                </div>



            </div>

            {/* scroll donw */}

            <div className="absolute bottom-14 right-20 hidden md:block overflow-hidden">
                <div className="  w-[100px] h-[100px] rounded-full bg-center bg-cover bg-no-repeat " style={{ backgroundImage: `url(${circleText})` }}>
                    <img src={downarrow} alt="" className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[20px] animate-ping aimate-bounse' />
                </div>

            </div>
            {/* second section  */}

            <div className=" h-[300px] md:h-screen items-center justify-center flex text-center px-[20px] md:px-[100px] text-[#5C6C68]">
                <h2 className='text-2xl md:text-[64px] leading-snug  font-anti anim '>The stay at Courtyard Castle  means enjoying every moment. Relax. Find inspiration. Be fascinated.</h2>
            </div>

            <div className=" px-[20px] md:px-[100px] w-full  relative hidden md:block md:h-[400px] flex items-center justify-between">
                <div className=''>
                    <h2 className='text-[#5C6C68] text-2xl font-anti absolute -left-16 md:left-0  rotate-[-90deg]'>Hotel & Resorts <span data-aos='fade-top' data-aos-delay='600' className='border-b-3 w-24 h-[1px] bg-[#5C6C68] inline-block'></span></h2>
                </div>

                <div className=" ">
                    <h2 className='text-4xl md:text-[128px] text-[#5C6C68] font-anti opacity-50 ' data-aos='zoom-in' data-aos-delay='600'>SPACES</h2>

                    <h4 className='text-[#5C6C68] font-urban text-2xl md:text[40px]  absolute text-center bottom-0 left-1/2 -translate-x-1/2 '>
                        COME AS YOU ARE AND WE WILL TAKE CARE OF THE REST
                    </h4>
                </div>
            </div>
            <div className="block md:hidden px-[10px]">
                <h2 className='text-4xl md:text-[128px] text-[#5C6C68] font-anti opacity-50 ' data-aos='zoom-in' data-aos-delay='600'>SPACES</h2>

                <h4 className='text-[#5C6C68] font-urban text-2xl md:text[40px]  absolute text-center bottom-0 left-1/2 -translate-x-1/2 '>
                    COME AS YOU ARE AND WE WILL TAKE CARE OF THE REST
                </h4>
            </div>


            {/* carosuel slider-1 */}
            <div className="h-[500px] md:h-screen my-[20px] md:my-[100px] relative  ">
                <Slider />
            </div>

            {/* hover rooms */}

            <div className=" h-auto md:h-screen bg-[#DEE2E1] grid grid-cols-1 place-items-center md:grid-cols-3 px-[10px] md:px-[100px] text-[#5C6C68] font-[anti] ">


                <div className="relative hover:text-white ">
                    <h2 className='text-2xl md:text-5xl hover:text-white text-center cursor-pointer absolute top-1/2 left-1/2  translate-y-1/2 z-10'>Rooms</h2>
                    <div className="relative perspective-1000">
                        <div className="transform-style-preserve-3d">
                            <img src={suites} alt="suites" className='w-full opacity-0 hover:opacity-100 hover:scale-x-110 hover:scale-y-150 hover:rounded-lg transform transition-transform duration-300 -z-10' />
                        </div>
                    </div>
                </div>
                <div className="relative hover:text-white ">
                    <h2 className='text-2xl md:text-5xl hover:text-white text-center cursor-pointer absolute top-1/2 left-1/2  translate-y-1/2 z-10'>Suites</h2>
                    <div className="relative perspective-1000">
                        <div className="transform-style-preserve-3d">
                            <img src={suites} alt="suites" className='w-full opacity-0 hover:opacity-100 hover:scale-x-110 hover:scale-y-150 hover:rounded-lg transform transition-transform duration-300 -z-10' />
                        </div>
                    </div>
                </div>
                <div className="relative hover:text-white ">
                    <h2 className='text-2xl md:text-5xl hover:text-white text-center cursor-pointer absolute top-1/2 left-1/2  translate-y-1/2 z-10'>Villas</h2>
                    <div className="relative perspective-1000">
                        <div className="transform-style-preserve-3d">
                            <img src={suites} alt="suites" className='w-full opacity-0 hover:opacity-100 hover:scale-x-110 hover:scale-y-150 hover:rounded-lg transform transition-transform duration-300 -z-10' />
                        </div>
                    </div>
                </div>


            </div>

            {/* expolare */}

            <div className="h-auto relative my-[100px] px-[10px] md:px-[100px] space-y-14 text-[#5C6C68]">
                <h2 className='text-3xl md:text-[128px]  font-anti opacity-50'>EXPLORE</h2>

                <h4 className='text-center text-xl md:text-3xl md:text-[40px] font-urban py-[5px] md:py-20'>Explore the Nature tourist places near <br /> <br />Courtyard Castle</h4>

            </div>

            <div className="h-[200px] md:h-[400px]  relative mb-24">

                <ExpolareSlider />
            </div>



            {/* story */}

            <div className="h-auto px-[10px] md:px-[100px] py-[20px]">
                <h2 className='text-4xl md:text-[128px] font-anti text-[#5c6c68] text-right opacity-50' data-aos='zoom-in' data-aos-delay='600'>Story</h2>

            </div>
            <div className="flex items-center justify-between mb-[100px] w-fit">
                <img src={story} alt="" className='w-[300px] hidden md:block' data-aos='fade-right' data-aos-delay='600' />
                <div className="px-[10px] md:px-[50px] text-urban text-xl md:text-3xl text-[#494541]">
                    <p>
                        At Aeorian , we believe that the ultimate luxury lies in the time and connections we make. Hence, the facilities and experiences are carefully crafted to rejuvenate the senses and foster connections with nature, family, and friends. Relax, rejuvenate, and reconnect with the world around you .
                    </p>
                </div>
            </div>

            {/* experince */}

            <h2 className='text-4xl md:text-[128px] text-[#5c6c68] font-anti px-[10px] md:px-[100px] sm:py-10px md:my-44 opacity-50' data-aos='zoom-in' data-aos-delay='600'>Experience</h2>

            <div className="bg-[#DEE2E1] h-[500px] py-[50px] text-[#5C6C68] text-center font-urban space-y-4 md:space-y-11">
                <h2 className=' text-xl md:text-3xl'>Explore our Virtual tour for our premium , <br /> resort for our visitors in to life</h2>
                <p>At Aeorian , we believe that the ultimate luxury lies in the time and .</p>
                <button className='py-2 px-4  border border-gray-500'><span><img src={rightarrow} alt="" className='inline pr-2' /></span><span className='inline'>Explore Virual tour</span></button>
            </div>

            <div className="relative h-[50vh] md:h-[100vh] w-full">
                <div className=" h-[300px] md:h-screen bg-[#DEE2E1] items-center justify-center flex min-w-full sticky top-[50px] md:top-[100px]" >

                    <iframe
                        src="https://cdn.illusiview.com/Arch4Foundation/index.htm"
                        frameborder="0"
                        className='w-[80vw] h-screen object-contain your-image-class' style={{ transform: 'scale(0.9)' }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                    {/* <img src={videothumb} alt="videothumb" className='w-[80vw] h-screen object-contain your-image-class' style={{ transform: 'scale(0.9)' }} /> */}
                    {/* <img src={videothumb} alt="videothumb" className='w-full block md:hidden'  ref={imageRefs}/> */}

                </div>
            </div>


            {/* same page  */}

            <div className=" px-[10px] md:px-[100px]  relative my-[80px] h-[300px] flex items-center justify-between">
                <div className=''>
                    <h2 className='text-[#5C6C68] text-2xl font-anti absolute -left-16 md:left-0  rotate-[-90deg]'>Hotel & Resorts <span className='border-b-3 w-24 h-[1px] bg-[#5C6C68] inline-block'></span></h2>
                </div>

                <div className="text-[#5c6c68] ">
                    <h2 className='text-2xl md:text-[128px] text-[#5C6C68] font-anti opacity-50' data-aos='zoom-in' data-aos-delay='600'>Dinning</h2>

                    <h4 className='text-[#5C6C68] font-urban text-xl md:text[40px]  absolute text-center bottom-0 left-1/2 -translate-x-1/2 '>
                        Explore our dinning Indian  traditional food for our premium , resort for our visitors in to life
                    </h4>
                </div>
            </div>

            {/* gallery */}

            <div className=" h-auto md:h-screen px-[10px] md:px-[100px]  gap-3 grid md:grid-cols-3 grid-cols-2">
                <div className="gals">
                    <img src={gallery1} alt="" className='object-contain ' data-aos='zoom-in' data-aos-delay='600' />
                </div>
                <div className="flex flex-col gap-2">
                    <div className="gals">
                        <img src={gallery2} alt="" className='object-contain' data-aos='zoom-in' data-aos-delay='600' />
                    </div>
                    <div className="gals">
                        <img src={gallery3} alt="" className='object-contain' data-aos='zoom-in' data-aos-delay='600' />
                    </div>
                </div>
                <div className=" gap-2 hidden md:block">
                    <div className="my-2 gals">
                        <img src={gallery4} alt="" className='object-contain' data-aos='zoom-in' data-aos-delay='600' />
                    </div>
                    <div className="gals">
                        <img src={gallery5} alt="" className='object-contain' data-aos='zoom-in' data-aos-delay='600' />
                    </div>
                </div>
            </div>
            <div className="p-[10px] grid grid-cols-2 gap-2 md:hidden " data-aos='zoom-in' data-aos-delay='600'>
                <div className="gals">
                    <img src={gallery4} alt="" className='object-contain' data-aos='zoom-in' data-aos-delay='600' />
                </div>
                <div className="gals">
                    <img src={gallery5} alt="" className='object-contain' data-aos='zoom-in' data-aos-delay='600' />
                </div>
            </div>

            {/* revievs */}

            <div className=" mt-[100px] md:mt-[200px] px-[10px] md:px-[100px]">
                <h2 className='text-4xl md:text-[128px] font-anti my-12 md:my-44 opacity-50'>Reviews</h2>
            </div>

            <div className=" h-[500px] md:h-screen bg-[#DEE2E1] relative">
                <div className="md:px-[100px] px-[10px]">
                    <h2 className='leading-snug text-3xl md:text-[64px] text-[#5C6C68] absolute -top-[35px] md:-top-[100px]'>
                        What Our <br />
                        Customers Are Saying
                    </h2>
                </div>

                <div className="w-full overflow-hidden">
                    <TestiData />
                </div>
            </div>

            <div className=" px-[10px] md:px-[100px]  relative my-[180px] h-auto flex items-center justify-between">
                <div className=''>
                    <h2 className='text-[#5C6C68] text-2xl font-anti absolute -left-16 md:left-0   rotate-[-90deg]'>Hotel & Resorts <span className='border-b-3 w-24 h-[1px] bg-[#5C6C68] inline-block'></span></h2>
                </div>

                <div className=" ">
                    <h2 className='text-4xl md:text-[128px] text-[#5C6C68] font-anti opacity-50'>Dinning</h2>
                </div>

            </div>
            <div className="grid grid-cols-1 md:grid-cols-3  px-[10px] md:px-[100px] gap-4 mb-12 ">
                <img src={imgOne} alt="" className='object-contain sticky top-14 md:top-0 -z-20 ' data-aos='fade-up' data-aos-delay='600' />
                <img src={imgTwo} alt="" className='object-contain sticky top-14 md:top-0 -z-10' data-aos='fade-down' data-aos-delay='600' />
                <img src={imgThree} alt="" className='object-contain' data-aos='fade-up' data-aos-delay='600' />
            </div>

        </>
    )
}

export default Hero