import React, { useEffect } from 'react'
import bg from '../../assets/images/bg-1.png'
import downarrow from '../../assets/images/down-arrow.svg'
import circleText from '../../assets/images/circle-text.png'
import aboutOne from '../../assets/images/about-1.png'
import aboutTwo from '../../assets/images/about-2.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>

            <div className="slider relative h-screen w-full bg-gradient-to-tl from-gray-700 to-gray-700 -z-10" >
                <div className="h-screen  bg-center bg-cover bg-no-repeat object-contain w-full absolute mix-blend-overlay" style={{ backgroundImage: `url(${bg})` }}>
                </div>

            </div>

            <div className="h-screen absolute top-1/2 left-1/2 -z-10  transform -translate-x-1/2 -translate-y-1/2">

                <div className="h-screen items-center justify-center flex flex-col  text-center text-white space-y-10">
                    <h1 className='font-anti text-5xl md:text-[80px] font-w-[400px]'>Courtyard Castle</h1>
                    <p className='font-curban text-2xl'>
                        Culinary art is an important part of the <br />
                        unforgettable experience
                    </p>
                </div>



            </div>

            {/* scroll donw */}

            <div className="absolute bottom-14 right-20 hidden md:block">
                <div className="  w-[100px] h-[100px] rounded-full bg-center bg-cover bg-no-repeat " style={{ backgroundImage: `url(${circleText})` }}>
                    <img src={downarrow} alt="" className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[20px] animate-ping aimate-bounse' />
                </div>

            </div>


            {/* second section  */}

            <div className=" h-[300px] md:h-screen items-center justify-center flex text-center px-[20px] md:px-[100px] text-[#5C6C68]">
                <h2 className='text-2xl md:text-[64px] leading-snug  font-anti' data-aos='fade-up' data-aos-delay='600'>The idea of "Courtyard Castle" was born out of an enthusiasm for great food and culture.</h2>
            </div>


            <div className=" px-[20px] md:px-[100px] w-full  relative h-[300px] flex items-start justify-between">
                <div className=''>
                    <h2 className='text-[#5C6C68] text-2xl font-anti absolute -left-16 md:left-0  rotate-[-90deg]'>Hotel & Resorts <span data-aos='fade-top' data-aos-delay='600' className='border-b-3 w-24 h-[1px] bg-[#5C6C68] inline-block'></span></h2>
                </div>


                <div className=" ">
                    <h2 className='text-4xl md:text-[128px] text-[#5C6C68] font-anti opacity-50' data-aos='zoom-in' data-aos-delay='600'>The Story</h2>
                </div>
            </div>


            {/* about-section */}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 p-[10px] md:px-[100px]">
                <img src={aboutOne} alt="" className='object-contain ' data-aos='fade-down' data-aos-delay='600' />

                <div className=" px-[30px] font-urban text-xl leading-10 ">
                    <p data-aos='fade-up' data-aos-delay='600'>
                        At Courtyard , we believe that the ultimate luxury lies in the time and connections we make. Hence, the facilities and experiences are carefully crafted to rejuvenate the senses and foster connections with nature, family, and friends. Relax, rejuvenate, and reconnect with the world around you .
                    </p>
                </div>
            </div>

            <div className="relative">
                <div className="absolute h-[430px] hiddenmd:block md:bg-[#DEE2E1] w-full bottom-0 -z-10">

                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 px-[10px] md:px-[100px]  text-[#5C6C68] mt-[100px]  font-urban">

                    <div className="">
                        <p className='border-t-2 border-b-2 w-fit'>SPECIALIZE</p>
                        <h2 className='text-4xl md:text-[64px] leading-tight'>
                            Peacefull Life <br />
                            style
                        </h2>
                        <p className='text-xl my-8'>
                            The people, food and the prime locations make Rodich the perfect place good friends & family to come together and have great time.
                        </p>
                    </div>
                    <div className="">
                        <img src={aboutTwo} alt="" className='object-contain ' data-aos='zoom-in' data-aos-delay='600' />
                    </div>

                </div>

            </div>


            {/* about-third */}
            <div className="bg-[#DEE2E1] w-full">

                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 p-[10px] md:px-[100px]">
                    <img src={aboutOne} alt="" className='object-contain ' data-aos='zoom-in' data-aos-delay='600' />

                    <div className=" px-[30px] font-urban text-xl leading-relaxed  flex flex-col items-center justify-center ">
                        <h2 className='text-4xl md:text-[68px] text-[#5C6C68] font-anti opacity-50 py-8'>Contact Us</h2>
                        <p>
                            The people, food and the prime locations <br /> make Rodich the perfect place good fri
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default About