import React from 'react'
import hall from '../assets/images/hall.png'
import explore2 from '../assets/images/explore-2.png'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const ExpolareSlider = () => {
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
      };
    return (
        <>
            <Slider {...settings} className=''>
        
            <div className="bg-[#DEE2E1] min-w-full h-[200px] md:h-auto my-[50px] relative bottom-0">
                <div className="flex px-[10px] md:px-[100px] justify-between items-center -mt-[30px] md:-mt-[100px] text-[#5C6C68]">
                    <div className="font-urban -mt-[10px] md:-mt-[100px]">
                        <h2 className='leading-snug text-xl md:text-[60px] '>Pondicherry Mandapam</h2>

                        <p className=' leading-5 md:leading-snug text-md md:text-[20px]'>
                            At Aeorian , we believe that the  <br /> ultimate luxury lies in the time and  <br /> connections we make.
                        </p>

                    </div>
                    <div className="">
                        <img src={hall} alt="hall" className='object-contain w-[200px] md:w-[550px]' data-aos='zoom-in' data-aos-delay='600'/>
                    </div>
                </div>
            </div>
            <div className="bg-[#DEE2E1] min-w-full h-[200px] md:h-auto my-[50px] bottom-0">
                
                <div className="flex px-[10px] md:px-[100px] justify-between items-center -mt-[30px] md:-mt-[100px] text-[#5C6C68]">
                
                    <div className="font-urban -mt-[10px] md:-mt-[100px]">
                        <h2 className='leading-snug text-xl md:text-[60px] '>Pondicherry Mandapam</h2>

                        <p className=' leading-5 md:leading-snug text-md md:text-[20px]'>
                            At Aeorian , we believe that the  <br /> ultimate luxury lies in the time and  <br /> connections we make.
                        </p>

                    </div>
                    <div className="">
                        <img src={explore2} alt="hall" className='object-contain w-[200px] md:w-[550px]' />
                    </div>
                </div>
            </div>
            <div className="bg-[#DEE2E1] min-w-full h-[200px] md:h-auto my-[50px] bottom-0">
                <div className="flex px-[10px] md:px-[100px] justify-between items-center -mt-[30px] md:-mt-[100px] text-[#5C6C68]">
                    <div className="font-urban -mt-[10px] md:-mt-[100px]">
                        <h2 className='leading-snug text-xl md:text-[60px] '>Pondicherry Mandapam</h2>

                        <p className=' leading-5 md:leading-snug text-md md:text-[20px]'>
                            At Aeorian , we believe that the  <br /> ultimate luxury lies in the time and  <br /> connections we make.
                        </p>

                    </div>
                    <div className="">
                        <img src={hall} alt="hall" className='object-contain w-[200px] md:w-[550px]' />
                    </div>
                </div>
            </div>
        
          
        
            </Slider>
        </>
    )
}

export default ExpolareSlider