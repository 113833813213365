import React from 'react'
import testi from '../assets/images/testi.png'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
const TestiData = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <>
    <div className="w-[60vw] overflow-hidden m-auto">
    <Slider {...settings}>
   <div className=' py-[100px] md:pt-[120px]'>
        <div className="flex items-end justify-center">
            <div className="">
                <img src={testi} alt="reviewImg"  className='w-[400px] md:w-[300px] anims' data-aos='flip-left' data-aos-delay='600'/>
            </div>
           <div className="relative flex flex-col">
           <div className="side-r text-[#494541] my-6 mx-2 text-2xl font-urban font-bold ">
                <h3>GENEVA ROBEL</h3>
            </div>
            <div className="banner-b bg-[#5C6C68] text-[16px] font-urban w-auto md:w-[400px] h-auto md:py-[40px] py-[10px] px-[10px] md:px-[40px] testi-gal">
                <h6 className='text-white'> In voluptates iste soluta veniam beatae sed ipsa quos numquam. Perferendis eos similique sunt temporibus. Tempore rem libero deleniti adipisci nulla.</h6>
            </div>
           </div>
        </div>
    </div>
   <div className=' py-[100px] md:pt-[120px]'>
        <div className="flex items-end justify-center">
            <div className="">
                <img src={testi} alt="reviewImg"  className='w-[400px] md:w-[300px] anims' />
            </div>
           <div className="relative flex flex-col">
           <div className="side-r text-[#494541] my-6 mx-2 text-2xl font-urban font-bold ">
                <h3>GENEVA ROBEL</h3>
            </div>
            <div className="banner-b bg-[#5C6C68] text-[16px] font-urban w-auto md:w-[400px] h-auto md:py-[40px] py-[10px] px-[10px] md:px-[40px] testi-gal ">
                <h6 className='text-white'> In voluptates iste soluta veniam beatae sed ipsa quos numquam. Perferendis eos similique sunt temporibus. Tempore rem libero deleniti adipisci nulla.</h6>
            </div>
           </div>
        </div>
    </div>
   <div className=' py-[100px] md:pt-[120px]'>
        <div className="flex items-end justify-center">
            <div className="">
                <img src={testi} alt="reviewImg"  className='w-[400px] md:w-[300px] anims'/>
            </div>
           <div className="relative flex flex-col">
           <div className="side-r text-[#494541] my-6 mx-2 text-2xl font-urban font-bold ">
                <h3>GENEVA ROBEL</h3>
            </div>
            <div className="banner-b bg-[#5C6C68] text-[16px] font-urban w-auto md:w-[400px] h-auto md:py-[40px] py-[10px] px-[10px] md:px-[40px] testi-gal ">
                <h6 className='text-white'> In voluptates iste soluta veniam beatae sed ipsa quos numquam. Perferendis eos similique sunt temporibus. Tempore rem libero deleniti adipisci nulla.</h6>
            </div>
           </div>
        </div>
    </div>
   </Slider>
    </div>
  
    </>
  )
}

export default TestiData