import React from 'react'
import slider from '../assets/images/slider-1.png'
import slider2 from '../assets/images/slider-2.png'
import './Animation.css'
import Sliders from 'react-slick'

const Slider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (

    <>



      <div className="relative h-screen w-full">

        <div className="absolute top-0 left-0 h-[300px] w-full bg-[#C7D4D1]"></div>
        <div className=" mx-auto flex items-center justify-center absolute z-10 h-screen w-full">
                <div className="bg-white absolute z-10 p-[20px] w-[250px] md:w-[300px] text-wrap ">
                  <div className="border border-gray-300 p-[30px] text-center">
                    <p className='font-urban text-md md:text-xl anim' > Courtyard Castle , we believe that the ultimate luxury lies in the time and connections we make. Hence, the facilities and experiences are carefully </p>
                  </div>
                </div>

              </div>
        <div className=" w-full md:w-[80vw] m-auto overflow-hidden">
          <Sliders {...settings} className='w-full overflow-hidden sm:h-[500px] md:h-[80vh] relative'>

            <div className=" h-[300px] md:h-screen relative ">

              <div className="absolute flex items-center justify-center h-[500px] md:h-screen w-full ">
                <img src={slider} alt="" className='bg-center bg-cover h-[500px] md:h-screen slow-spin' />

              </div>

            </div>

            <div className="h-screen relative ">
            

              <div className="absolute flex items-center justify-center h-[500px] md:h-screen w-full">
                <img src={slider2} alt="" className='bg-center bg-cover  object-cover w-full h-[500px] md:h-screen slow-spin' />

              </div>
            </div>

            <div className="h-screen relative ">

              <div className="absolute flex items-center justify-center h-[500px] md:h-screen">
                <img src={slider} alt="" className='bg-center bg-cover  object-cover sm:w-full sm:h-full h-[500px] md:h-screen slow-spin' />

              </div>

            </div>

          </Sliders>

        </div>
      </div>

      {/* <div className="h-screen relative w-full ">
        <div className="bg-[#C7D4D1] h-[200px] md:h-[300px] hidden md:block absolute top-0 min-w-full"></div>
        <div className="absolute mx-auto h-screen w-full">
          <div className="bg-white absolute z-10 p-[20px] w-[250px] md:w-[300px] text-wrap left-80 translate-x-1/2 translate-y-1/2">
            <div className="border border-gray-300 p-[30px] text-center">
              <p className='font-urban text-md md:text-xl anim' > Courtyard Castle , we believe that the ultimate luxury lies in the time and connections we make. Hence, the facilities and experiences are carefully </p>
            </div>
          </div>

        </div>
        <div className="min-w-[80vw] sm:h-[500px] md:h-[80vh] relative">

          <Sliders {...settings} className='min-w-[80vw] overflow-hidden sm:h-[500px] md:h-[80vh] relative'>

            <div className=" h-[300px] md:h-screen relative ">

              <div className="absolute flex items-center justify-center h-[500px] md:h-screen w-full px-[10px] md:px-[100px]">
                <img src={slider} alt="" className='bg-center bg-cover h-[500px] md:h-screen' />

              </div>

            </div>

            <div className="h-screen relative ">

              <div className="absolute flex items-center justify-center h-[500px] md:h-screen w-full px-[10px] md:px-[100px]">
                <img src={slider2} alt="" className='bg-center bg-cover  object-cover w-full h-[500px] md:h-screen' />

              </div>
            </div>

            <div className="h-screen relative ">

              <div className="absolute flex items-center justify-center h-[500px] md:h-screen px-[10px] md:px-[100px]">
                <img src={slider} alt="" className='bg-center bg-cover  object-cover sm:w-full sm:h-full h-[500px] md:h-screen' />

              </div>

            </div>

          </Sliders>
        </div>


      </div> */}


      {/* <div className="">
        <div className="absolute h-[500px] md:h-screen items-center justify-center flex  w-full">


        </div>



        <Sliders {...settings} className='min-w-full overflow-hidden sm:h-[500px] md:h-screen relative'>

          <div className=" h-[300px] md:h-screen relative ">

            <div className="absolute flex items-center justify-center h-[500px] md:h-screen w-full px-[10px] md:px-[100px]">
              <img src={slider} alt="" className='bg-center bg-cover h-[500px] md:h-screen' />

            </div>

          </div>

          <div className="h-screen relative ">
            <div className="bg-[#C7D4D1] h-[200px] md:h-[400px] hidden md:block absolute bottom-0 min-w-full max-w-fit"></div>
            <div className="absolute flex items-center justify-center h-[500px] md:h-screen w-full px-[10px] md:px-[100px]">
              <img src={slider2} alt="" className='bg-center bg-cover  object-cover w-full h-[500px] md:h-screen' />

            </div>
          </div>

          <div className="h-screen relative ">
            <div className="bg-[#C7D4D1] h-[200px] md:h-[400px] hidden md:block absolute top-0 min-w-full"></div>
            <div className="absolute flex items-center justify-center h-[500px] md:h-screen px-[10px] md:px-[100px]">
              <img src={slider} alt="" className='bg-center bg-cover  object-cover sm:w-full sm:h-full h-[500px] md:h-screen' />

            </div>

          </div>

        </Sliders>
      </div> */}


    </>


  )
}

export default Slider